<template>
  <!--
    Used to add Video Recorder Configurations
  -->
  <div class="videoRecorderConfigurationAdd">
    <h4 class="m-0 mt-3">
      {{ $t('addVideoRecorderConfigurationComp.config') }}
    </h4>
    <hr class="m-0 mb-3">
    <div class="form-group m-form__group row mb-2">
      <label class="col-4 col-form-label">{{ $t('camera') }}</label>
      <div class="col-8">
        <select
          v-model="configuration.cameraId"
          :class="['form-control', { 'is-invalid': this.$validator.errors.has('Camera') }]"
        >
          <option
            v-for="(camera, index) in cameras"
            :key="`cutConfigListItemAdd_Option${ index }`"
            :value="camera.id"
          >
            {{ camera.id }} - {{ camera.ipAddress }}
          </option>
        </select>
        <span
          v-show="errors.has('Camera')"
          class="badge badge-danger"
        >{{ errors.first('Camera') }}</span>
      </div>
    </div>

    <hr class="m-0 mb-3">
    <div class="form-group m-form__group row mb-2">
      <label class="col-4 col-form-label">{{ $t('orderId') }}</label>
      <div class="col-8">
        <input
          v-model="configuration.orderId"
          :class="['form-control', { 'is-invalid': this.$validator.errors.has('OrderId') }]"
          type="number"
          min="0"
          max="100"
          step="1"
        >
        <span
          v-show="errors.has('OrderId')"
          class="badge badge-danger"
        >{{ errors.first('OrderId') }}</span>
      </div>
    </div>

    <hr class="m-0 mb-3">
    <div class="form-group row mb-2">
      <label class="col-12 col-sm-3 col-form-label">{{ $t('previewCamera') }}</label> 
      <div class="col-12 col-sm-9">
        <toggle-button
          v-model="configuration.previewCamera"
          :labels="{ checked: $t('true'), unchecked: $t('false') }"
          :class="['m-0 ml-4 mt-2', { 'is-invalid': this.$validator.errors.has('PreviewCamera') }]"
          :width="70"
          :height="30"
          :font-size="12"
          @change="changeOrderId"
        />
      </div>
    </div>
    <span
      v-show="errors.has('PreviewCamera')"
      class="badge badge-danger"
    >{{ errors.first('PreviewCamera') }}</span>

    <hr class="m-0 mb-3">
    <div class="form-group row mb-2">
      <label class="col-12 col-sm-3 col-form-label">ActiveOnStart</label> 
      <div class="col-12 col-sm-9">
        <toggle-button
          v-model="configuration.activeOnStart"
          :labels="{ checked: $t('true'), unchecked: $t('false') }"
          :class="['m-0 ml-4 mt-2', { 'is-invalid': this.$validator.errors.has('ActiveOnStart') }]"
          :width="70"
          :height="30"
          :font-size="12"
        />
      </div>
    </div>
    <span
      v-show="errors.has('ActiveOnStart')"
      class="badge badge-danger"
    >{{ errors.first('ActiveOnStart') }}</span>

    <button
      class="btn btn-primary mt-2 float-right"
      @click="updateConfig"
    >
      <font-awesome-icon
        class="mr-1"
        icon="upload"
      />
      <span>
        {{ $t('save') }}
      </span>
    </button>
    <div class="clearfix" />
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "EditVideoRecorderConfiguration",
  mixins: [
    errorMixin
  ],
  props: {
    configuration: {
      type: Object,
      required: true
    },
    installationId: {
      type: String,
      required: true
    },
    laneNumber: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  data () {
    return {
      cameras: [],
      loading: true
    }
  },
  created () {
    this.getCameraConfigs();
  },
  methods: {
    updateConfig () {
       let params = {
        videoRecorderConfig: this.configuration,
        comment: null
      }
      let url = `/Config/UpdateVideoRecorderConfig?installationId=${ this.installationId }&videoRecorderId=${ this.configuration.id }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.post(url, params)
        .then((response) => {
          if (response.status == 204) {
            this.$emit('hide');
            this.$emit('setReloadButton', true);
            this.$snotify.error(this.$t('configReload.failedReload'));
          } else {
            this.$emit('setReloadButton', false);
            if (response.status != 200) {
              return;
            }
          }

          this.$emit('reloadConfig');
          this.$snotify.success(this.$t('videoDirectorConfigFormComponent.configWasSaved'));
        })
        .catch((error) => {
          this.$snotify.error(this.$t('videoDirectorConfigRecorderFormComponent.configNotSaved'));
          this.error_clear();
          this.error_validate(error);
        });
    },
    getCameraConfigs () { 
      let url = `/Config/GetCameraManagementConfig?installationId=${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.get(url)
        .then((response) => {
          if (!response || !response.data) {
            return;
          }
          this.cameras = response.data.cameraConfigs; 
        })
        .finally(() => {
          if (this.cameras != null) {
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    changeOrderId () {
      if(this.configuration.previewCamera) {
        this.configuration.orderId += 100;
      } else {
        this.configuration.orderId -= 100;
      }
    },
  }
}
</script>
